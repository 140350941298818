import Combo31 from "./combos/Combo31";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  return (
    <div className="App">
      <Combo31 />
    </div>
    
  );
}
