import {React, useState, useRef} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
// import styles from "../styles/Home.module.css"
import Form from 'react-bootstrap/Form';
import { supabase } from './supabase';
import { nanoid } from 'nanoid';

function CheckoutModal({userImagesArr, comboId }) {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [notes, setNotes] = useState("");

    const [activeCountry, setActiveCountry] = useState("Vietnam");
    const [activeCity, setActiveCity] = useState("Ho Chi Minh");
    const [activeState, setActiveState] = useState("");
    const [activeCities, setActiveCities] = useState(["Ho Chi Minh", "Hue", "Hanoi", "Da Nang", "Binh Dinh"]);

    // country array
    const countries = ["Vietnam", "United States", "Canada", "Thailand", "Singapore"];

    // city array based on country
    const vietnamCities = ["Ho Chi Minh", "Hue", "Hanoi", "Da Nang", "Binh Dinh"];
    const usStates = ["Pennsylvania", "New York", "California"];

    var quantity = userImagesArr.length;
    

    

    const id = nanoid();
    const date = new Date();

    const numberFormatter = Intl.NumberFormat('en-US');
    function formatted(n){
        return numberFormatter.format(n); 
    }

    function handleCountryClick(country){
        setActiveCountry(country);

        switch(country){
            case "Vietnam": 
                setActiveCities(vietnamCities);
                setActiveCity(vietnamCities[0]);
                break;
            case "United States": 
                setActiveCities(usStates);
                setActiveCity(usStates[0]);
            break;
            default:
                break;
        }
    }

    function handleCityClick(city){
        setActiveCity(city);
    }

    function resetValues(){
        setName("")
        setAddress("")
        setEmail("")
        setPhoneNumber("")
        setNotes("")
    }

    function handleClose(){
        setShow(false);
    }

    function urlToFile(url){
        let arr = url.file.split(",")
        
        let mime = arr[0].match(/:(.*?);/)[1]
        let data = arr[1]

        let dataStr = atob(data)
        let n = dataStr.length
        let dataArr = new Uint8Array(dataStr.length)

        while (n--){
            dataArr[n] = dataStr.charCodeAt(n)
        }

        let file = new File([dataArr], url.id, {type: mime})

        console.log(file);
        
        return file;
    }

    function blobToFile(blob){
        var file = new File([blob.img], blob.id);
        console.log(file)
        return file;
    }


    const handleShow = () => setShow(true);

    async function handleOrder(){
        if(name != "" && email != "" && phoneNumber != "" && address != "" && userImagesArr.length != 0){
            const {data, error} = await supabase.from('combo_orders').insert([{
                quantity: quantity,
                name: name,
                email: email,
                address: address,
                phone_number: phoneNumber,
                notes: notes,
                order_id: comboId+id+email,
                country: activeCountry,
                city: activeCity,
                state: activeState
            }])

            userImagesArr.map(img => {
                supabase.storage
                .from('user-images')
                .upload(`${comboId+id+email}/${img.id}`, urlToFile(img));

                // console.log(urlToFile(img));
            })

            if (error) {
                console.log(error)
            } else {
                console.log('success');
                setShow(false);
            }
    
        }

        // console.log(userImagesArr);
    }
    
            
        

    

  return (
    <div className="checkoutModal">
    <button onClick={handleShow} className="checkoutBtn">
        {/* Đặt hàng ({formatted(imageList.length*90000)} VNĐ) */}
        Checkout ({userImagesArr.length})
        <img style={{height: "21px"}} src="/img/web/icons8-shopping-cart-30.png" />
    </button>

      <Modal show={show} onHide={handleClose} centered >
        <Modal.Header closeButton >
            <h4>Order Confirmation</h4>   
        </Modal.Header>
        <Modal.Body >
            <Form>
                <Form.Group className="mb-3" >
                
                <div className="priceTabData">
                    <div className="priceCol">
                        <span style={{fontWeight: "bold"}}>Combo ID:</span>
                        <span style={{fontWeight: "bold"}}>{comboId}</span>
                    </div>
                    
                    <div className="priceCol">
                        <span style={{fontWeight: "bold"}}>Shipping fee:</span>
                        <span style={{fontWeight: "bold"}}>30,000</span>
                    </div>
                    <div className="priceCol">
                        <span style={{fontWeight: "bold"}}>Total:</span>
                        <span style={{fontWeight: "bold"}}>{formatted(userImagesArr.length*90000)} VND</span>
                    </div>
                </div>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" onChange={e => setName(e.target.value)} autoFocus/>
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control type="text"  placeholder="Your phone number" onChange={e => setPhoneNumber(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text"  placeholder="Your email" onChange={e => setEmail(e.target.value)} />
                </Form.Group>

                <div className="countrySelect">
                <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {activeCountry}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}

                    {countries.map((country) => {
                        return(
                            <Dropdown.Item onClick={() => handleCountryClick(country)}>{country}</Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>

                </Dropdown>

                <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    {activeCity}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}

                    {activeCities.map((city) => {
                        return(
                            <Dropdown.Item onClick={() => handleCityClick(city)}>{city}</Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
                </Dropdown>
                    
                </div>

                <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text"  placeholder="Your address" onChange={e => setAddress(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control type="text"  placeholder="Your notes" onChange={e => setNotes(e.target.value)} />
                </Form.Group>

                <button type="button" className="confirmBtn" onClick={handleOrder}>
                    Confirm your order
                </button>
            </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CheckoutModal