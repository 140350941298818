import {React, useRef, useState, useEffect} from 'react'
import '../styles/styles.css'
import CheckoutModal from "../CheckoutModal";
// import {useGlobalContext} from "../context";

function Combo31() {
    let comboCode = "combo31";
    const f = useRef(null);
    var [chosenImageClassName, setChosenImageClassName] = useState("");
    var [firstImgSrc, setFirstImgSrc] = useState("https://picsum.photos/65/65");
    var [secondImgSrc, setSecondImgSrc] = useState("https://picsum.photos/65/65");
    var [thirdImgSrc, setThirdImgSrc] = useState("https://picsum.photos/65/65");
    var [userImagesArr, setUserImagesArr] = useState([]);

    var [firstImgSrcString, setFirstImgSrcString] = useState("");
    var [secondImgSrcString, setSecondImgSrcString] = useState("");
    var [thirdImgSrcString, setThirdImgSrcString] = useState("");


    const quantity = userImagesArr.length;
    const price = 30;

    function urlToFile(url){
        let arr = url.img.split(",")
        
        let mime = arr[0].match(/:(.*?);/)[1]
        let data = arr[1]

        let dataStr = atob(data)
        let n = dataStr.length
        let dataArr = new Uint8Array(dataStr.length)

        while (n--){
            dataArr[n] = dataStr.charCodeAt(n)
        }

        let file = new File([dataArr], url.id, {type: mime})

        console.log(file);
        
        return file;
    }

    function insertImage(file, id){
        if(file && id){
            const presentId = userImagesArr.findIndex(obj => obj.id === id);

            if(presentId >= 0){
                userImagesArr.splice(presentId, 1);

                setUserImagesArr([{file: file, id: id}, ...userImagesArr]);
            } else {
                setUserImagesArr([{file: file, id: id}, ...userImagesArr]);
            }
        };
    }



    const comboFileChange = async (e) => {

        if(e.target.files && e.target.files.length > 0){
            var file = e.target.files[0];
            var imageFile = await readFile(file);
            switch(chosenImageClassName) {
                case "combo31-1":
                    setFirstImgSrc(imageFile);
                    insertImage(imageFile, 1);
                    break;
                case "combo31-2":
                    setSecondImgSrc(imageFile);
                    insertImage(imageFile, 2);
                    break;
                case "combo31-3":
                    setThirdImgSrc(imageFile);
                    insertImage(imageFile, 3);
                    break;
                default:
                    break;
            }
        }
    } 

    function handleAddToCart(){
        console.log(firstImgSrc);
    }

    function readFile(file) {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.addEventListener("load", () => resolve(reader.result), false);
          reader.readAsDataURL(file);
        });
    }



    const handleImageClick = event => {
        let classname = event.currentTarget.className;
        setChosenImageClassName(classname);
        f.current.click();
      };

  return (
    <div className="mainContainer">
        <div className="mainContainerLeft">
            <div className="comboContainer">
            <img src="/img/room/1.jpg" className="comboBackground" />
                {/* <div className="comboInner"> */}
                    <div className='comboImage' id="combo31-1" >
                        <img src="/img/frame/01-0001-01-02-00.png" className='combo31-1' onClick={handleImageClick} style={{zIndex: 2}}/>
                        <img src={firstImgSrc} className='comboImg' />
                    </div>
                    
                    <div className='comboImage' id="combo31-2" >
                        <img src="/img/frame/01-0001-01-02-00.png" className='combo31-2' onClick={handleImageClick} style={{zIndex: 2}}/>
                        <img src={secondImgSrc} className='comboImg' />
                    </div>

                    <div className='comboImage' id="combo31-3" >
                        <img src="/img/frame/01-0001-01-02-00.png" className='combo31-3' onClick={handleImageClick} style={{zIndex: 2}}/>
                        <img src={thirdImgSrc} className='comboImg' />
                    </div>
                {/* </div> */}
            </div>
        </div>
        <div className="mainContainerRight">
            <div className="comboDetails">
                <h3>Combo 3 pictures 100cm x 100cm</h3>
                <p>Price: ${price}</p>
                {/* <button onClick={handleAddToCart} className='confirmBtn'>Add to cart</button> */}
            </div>
        </div>
        <input type="file" style={{display: "none"}} ref={f} onChange={(e) => {
            comboFileChange(e);
          }} />

        <CheckoutModal userImagesArr={userImagesArr} comboId={comboCode} />
    </div>
  )
}

export default Combo31